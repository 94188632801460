export const ERROR_SCOPE = 'VideoCall: '

export const EVENTBUS_CONTEXT = 'video-call'
export const EVENTBUS_COMMAND_BASE = 'base'
export const RANDOM_CALL_AUTOSTART = 'random-call-autostart'
export enum VideoCallMessageType {
  giftGot = 5,
  giftSent = 6,
  messageGot = 7,
  messageSent = 8,
  messageSentNeedReg = 100,
}
export const LOW_COINS = 600
